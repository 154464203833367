<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="green" @click="openCreateColorDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="green" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="green--text">
                        <Avatar :attr="mainProcolor" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No colors yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <!-- <ColorListItem :attr="item" :link="!selectOne"></ColorListItem> -->
                    <ColorListItem :attr="item"></ColorListItem>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="green" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="green--text">
                            <Avatar :attr="mainProcolor" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-color', params: { accountId, colorId: item.id } }">
                            <!-- <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon> -->
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateColor">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createColor" ref="createColorRef" @submit="validateCreateOrigin" onSubmit="return false;" @keyup.enter.native="validateCreateOrigin">
                    <div v-if="add">
                        <v-select :items="addColorChoices" v-model="addColorId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newOriginName
                            label="Display Name"
                            :rules="newOriginNameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addOrigin" :disabled="!createColor" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createOrigin" :disabled="!createColor" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateOriginDialog v-model="createOriginDialogVisible" @cancel="createOriginDialogVisible = false" @created="createOriginDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import ColorListItem from '@/components/list-item/ColorListItem.vue';
// import CreateOriginDialog from '@/components/account-dashboard/CreateOriginDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        ColorListItem,
        // CreateOriginDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the origin; maybe create a different component for "select a origin"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createOriginDialogVisible: false,
        // create origin
        displayCreateColor: false,
        createColor: null,
        newColorName: null,
        newColorNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Email origin name is required',
        ],
        // add origin
        addColorChoices: [],
        addColorId: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            if (this.selectOne) {
                return 'Select a color';
            }
            switch (this.list.length) {
            case 0:
                return 'No colors';
            case 1:
                return '1 color';
            default:
                return `${this.list.length} colors`;
            }
        },
    },

    watch: {
        displayCreateColor(value) {
            if (value && (this.add || this.create)) {
                this.initAddColorChoices();
            }
        },
    },

    methods: {
        async loadColorList() {
            try {
                this.$store.commit('loading', { loadColorList: true });
                const match = {};
                if (typeof this.domain === 'string') {
                    match.domain = this.domain;
                }
                const response = await this.$client.account(this.$route.params.accountId).color.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadColorList failed', err);
            } finally {
                this.$store.commit('loading', { loadColorList: false });
            }
        },
        onClickItem(id) {
            /*
            if (this.selectOne) {
                this.$emit('selected', { colorId });
            }
            */
            this.$emit('selected', { id });
        },
        openCreateColorDialog() {
            // this.createOriginDialogVisible = true;
            // TODO: domain doesn't need to be in this query
            this.$router.push({ name: 'account-create-color', params: { accountId: this.$route.params.accountId }, query: { domain: this.$route.params.domain } });
        },
        // async createOrigin() {
        //     this.error = false;
        //     console.log('createOrigin');
        //     const request = {
        //         // name: this.name,
        //         name: this.newOriginName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if origin arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the origin to an appropriate location after origin is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).currentAccount.site.create(request);
        //     console.log('createOrigin response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const origin = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', origin);
        //         this.$emit('added-site', origin);
        //         this.displayCreateColor = false;
        //         this.newOriginName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateOrigin() {
        //     if (this.$refs.createColorRef.validate()) {
        //         this.createOrigin();
        //     }
        // },

        // async addOrigin() {
        //     const origin = { id: this.addColorId, label: this.addColorChoices.find((item) => item.value === this.addColorId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', origin);
        //     this.displayCreateColor = false;
        //     this.addColorId = null;
        // },
        // async initAddOriginChoices() {
        //     // create a map of which origin ids are already in the list
        //     const originMap = {};
        //     this.list.forEach((item) => {
        //         originMap[item.id] = true;
        //     });
        //     // when we show the add/create origin dialog, load the list of available origins to add to the policy (list of all origins less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).currentAccount.site.list();
        //     console.log(`OriginViewList.vue fetch origins result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove origins already in the policy, then convert the available origins to a choice list for the v-select [ { text, value }, ... ]
        //         this.addColorChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadColorList();
    },
};
</script>
